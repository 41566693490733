import linq from 'linq';
import moment from 'moment';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import GeneralApi from '../api/GeneralApi';
import eventMenuState from '../atoms/eventMenuState';
import loaderState from '../atoms/loaderState';
import orderState from '../atoms/orderState';
import showSignInState from '../atoms/showSignInState';
import showSignUpState from '../atoms/showSignUpState';
import BlockInfo, { InfoType } from '../components/BlockInfo';
import Button from '../components/Button';
import Header from '../components/Header';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import SolidLine from '../components/SolidLine';
import SpacerTable from '../components/SpacerTable';
import CacheHelper from '../helpers/CacheHelper';
import CurrencyHelper from '../helpers/CurrencyHelper';
import DateHelper from '../helpers/DateHelper';
import LocationHelper from '../helpers/LocationHelper';
import NumberHelper from '../helpers/NumberHelper';
import SVGHelper from '../helpers/SVGHelper';
import UserHelper from '../helpers/UserHelper';
import { IEvent } from '../interfaces/IEvent';
import { IEventDate } from '../interfaces/IEventDate';
import { IOrder } from '../interfaces/IOrder';
import SVGGoToEvent from '../svg/SVGGoToEvent';
import SVGOptions from '../svg/SVGOptions';
import SVGPrint from '../svg/SVGPrint';
import SVGTicket from '../svg/SVGTicket';
import { AdmissionTypes } from './Editor/event/TicketSetupSection';
import './TicketsPage.scss';
import { ITicket } from '../interfaces/ITicket';
import { set } from '../svg_pan_zoom/features/common';

interface IProps {
  ticketGuid?: string;
}

const TicketsPage: React.FC<IProps> = (props) => {
  const location = useLocation();

  const { ticketGuid } = useParams();
  const [, setOrder] = useRecoilState(orderState);
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const [, setShowSignUp] = useRecoilState(showSignUpState);
  const [, setShowSignIn] = useRecoilState(showSignInState);
  const [, setEventMenu] = useRecoilState(eventMenuState);

  const [busyMessage, setBusyMessage] = useState('Loading tickets...');
  const [event, setEvent] = useState<IEvent>(null);
  const [orders, setOrders] = useState<IOrder[]>(null);
  const [authRequired, setAuthRequired] = useState<boolean>(false);

  const url = location.pathname;

  const isPrintPage = url.split('/')[1].toLowerCase() === 'organisation' && url.split('/')[8].toLowerCase() === 'print';

  const eventTag = url.split('/')[isPrintPage ? 4 : 1];
  const domain = url.split('/')[isPrintPage ? 5 : 2];
  const [eventDatePart, setEventDatePart] = useState(location.pathname.split('/')[isPrintPage ? 6 : 3]);
  const [eventTimePart, setEventTimePart] = useState(location.pathname.split('/')[isPrintPage ? 7 : 4]);

  const isAdmin = event && event.Organisation && UserHelper.isCurrentUserAdmin(event.Organisation);

  useEffect(() => {
    var root = document.getElementsByTagName('html')[0];
    root.classList.add('white');
    return () => {
      root.classList.remove('white');
    };
  }, []);

  useEffect(() => {
    loadTickets();
  }, [location]);

  const loadTickets = () => {
    const _eventDatePart = location.pathname.split('/')[isPrintPage ? 6 : 3];
    const _eventTimePart = location.pathname.split('/')[isPrintPage ? 7 : 4];

    setEventDatePart(_eventDatePart);
    setEventTimePart(_eventTimePart);

    const _eventTag = LocationHelper.getQueryVariable('event');
    const email = LocationHelper.getQueryVariable('email');

    if (ticketGuid && ticketGuid.length > 0) {
      GeneralApi.request('GET', `/api/Tickets?id=${ticketGuid}`)
        .then((response) => {
          if (response.length == 0) {
            setBusyMessage('Sorry, we cannot find your tickets.');
            return;
          }

          const orderEvent: any = (response[0] as any).Event;

          CacheHelper.eventWithMessage(setLoaders, loaders, orderEvent.EventTag).then((event) => {
            setEvent(event);
            setOrders(response);
            setBusyMessage(null);
          });
        })
        .catch((message) => {
          alert(message);
          setBusyMessage('Sorry, we cannot find your tickets.');
        });
    } else if (isPrintPage) {
      if (!UserHelper.currentUser || UserHelper.currentUser.Guest) {
        setAuthRequired(true);
        setBusyMessage(null);

        return;
      }

      GeneralApi.request('GET', `/api/AllTickets?eventTag=${eventTag}&date=${_eventDatePart}&time=${_eventTimePart}`)
        .then((response) => {
          if (response.length == 0) {
            setBusyMessage('Sorry, we cannot find your tickets.');
            return;
          }

          const orderEvent: any = (response[0] as any).Event;

          CacheHelper.eventWithMessage(setLoaders, loaders, orderEvent.EventTag).then((event) => {
            setEvent(event);
            setOrders(response);
            setBusyMessage(null);
          });
        })
        .catch((message) => {
          alert(message);
          setBusyMessage('Sorry, we cannot find your tickets.');
        });
    } else if (_eventTag && _eventTag.length > 0 && email && email.length > 0) {
      if (!UserHelper.currentUser || UserHelper.currentUser.Guest) {
        setAuthRequired(true);
        setBusyMessage(null);

        return;
      }

      GeneralApi.request('GET', `/api/AllTickets?eventTag=${_eventTag}&email=${email}`)
        .then((response) => {
          if (response.length == 0) {
            setBusyMessage('Sorry, we cannot find your tickets.');
            return;
          }

          const orderEvent: any = (response[0] as any).Event;

          CacheHelper.eventWithMessage(setLoaders, loaders, orderEvent.EventTag).then((event) => {
            setEvent(event);
            setOrders(response);
            setBusyMessage(null);
          });
        })
        .catch((message) => {
          alert(message);
          setBusyMessage('Sorry, we cannot find your tickets.');
        });
    }
  };

  const getSampleOrder = (e: IEvent, ed: IEventDate): IOrder => {
    return {
      EventDate: ed as any,
      OrderId: 54321,
      Tickets: 2,
      Discounts: [],
      AttendeeName: UserHelper.currentUser.Name,
      Id: 1,
      Currency: '£',
      Guid: '',
      PlacedBy: ` ${UserHelper.currentUser.Name} (${UserHelper.currentUser.Email})`,
      PlacedFor: UserHelper.currentUser.Email,
      Email: UserHelper.currentUser.Email,
      Price: CurrencyHelper.formatCurrency(e.CurrencySymbol, 7000 + (e.HandlingFee ? e.HandlingFee : 0)) as any,
      UserNotes: null,
      AdminNotes: null,
      Date: DateHelper.asDateAtTimeAmPm(moment().toString()),
      Seats: [
        {
          Cancelled: false,
          PaymentTaken: true,
          PaymentId: 1,
          PriceAsString: CurrencyHelper.formatCurrency(e.CurrencySymbol, 3500),
          PriceAsInt: 3500,
          CancelledDate: null,
          Guid: '',
          SeatCategoryDescription: '',
          CategoryDescription: null,
          QuestionAnswers: [],
          Column: 0,
          Row: 0,
          Group: 'A',
          Name: '1',
          GeneralAdmission: false,
          Quantity: 0,
          TicketCategoryId: 1,
          CategoryGroupId: null,
          CategoryName: 'Sample Ticket',
          CategoryColour: '#62D690',
          SeatCategoryId: 1,
          AdminNotes: null,
          UserNotes: null,
          SeatCategoryName: 'Stalls',
          SeatCategoryColour: '#c09a21',
        },
        {
          Cancelled: false,
          PaymentTaken: true,
          PaymentId: 1,
          PriceAsString: CurrencyHelper.formatCurrency(e.CurrencySymbol, 3500),
          PriceAsInt: 3500,
          CancelledDate: null,
          Guid: '',
          SeatCategoryDescription: '',
          CategoryDescription: null,
          QuestionAnswers: [],
          Column: 0,
          Row: 0,
          Group: 'A',
          Name: '2',
          GeneralAdmission: false,
          Quantity: 0,
          TicketCategoryId: 1,
          CategoryGroupId: null,
          CategoryName: 'Sample Ticket',
          CategoryColour: '#62D690',
          SeatCategoryId: 1,
          AdminNotes: null,
          UserNotes: null,
          SeatCategoryName: 'Stalls',
          SeatCategoryColour: '#c09a21',
        },
      ],
      Purchase: {
        SeatCount: 0,
        PurchaseCosts: {
          EnhancedRefundServiceFeeFormatted: '',
          EnhancedRefundServiceFee: 0,
          SeatyServiceFee: 0,
          TotalCostWithFees: 0,
          TotalCostWithoutFees: 0,
          TotalFeesFormatted: '',
          TotalCostWithFeesFormatted: '',
          TotalCostWithoutFeesFormatted: '',
          OrganisationServiceFee: 0,
          TotalFees: 0,
          HandlingFee: e.HandlingFee,
          ErrorMessage: null,
          CurrentUser: null,
        },
        Expired: false,
        Completed: false,
        PaymentIntentId: null,
        AddressLine1: '',
        Postcode: '',
        NameOnCard: null,
        Type: 'stripe',
        AbsorbFees: true,
        UseSavedPaymentDetails: false,
        SavePaymentDetails: false,
        OrderId: null,
        PaymentMethodId: null,
        Discounts: null,
        Discount: null,
        DiscountCode: null,
        AttendeeName: '',
        SessionId: null,
        Id: 1,
        Guid: '00000000-0000-0000-0000-000000000000',
        EventDateId: 0,
        Event: null,
        EventDate: null,
        UserId: null,
        Currency: null,
        PlacedBy: null,
        PlacedByUser: null,
        PlacedFor: null,
        Email: null,
        Price: null,
        PriceAsString: null,
        PriceAsInt: 0,
        QRUrl: null,
        Notes: null,
        PaymentTaken: false,
        PaymentType: null,
        UserNotes: null,
        AdminNotes: null,
        OrderNumber: null,
        Date: null,
        DateOrdered: '0001-01-01T00:00:00',
        Seats: null,
        Categories: null,
        OrderLogs: null,
        QuestionAnswers: null,
        ReferralId: null,
        ReferralName: null,
        ReferredTo: null,
        IsPurchase: false,
        CurrentUserIsOwner: false,
        GuidString: null,
        EventId: 0,
        AllowMarketing: false,
        AllowSurvey: false,
        CollectAtBoxOffice: false,
        ErrorMessage: null,
        CurrentUser: null,
      },
      QuestionAnswers: [],
    };
  };

  const loadSample = async () => {
    const eventData: IEvent = await CacheHelper.eventByTag(eventTag);

    if (!eventData) {
      setBusyMessage('Cannot find event...');
      return;
    }

    let matchingEventDateId = 0;
    Object.keys(eventData.EventDateIds).forEach((eventDateIdKey) => {
      if (
        moment(eventData.EventDateIds[eventDateIdKey]).format('DDMMMYYYY/HHmm') ===
        eventDatePart + '/' + eventTimePart
      ) {
        matchingEventDateId = parseInt(eventDateIdKey);
      }
    });

    if (matchingEventDateId) {
      if (eventData.Dates.length < 1) {
        setBusyMessage('Not enough event dates...');
        return;
      }
      const eventDateData = eventData.Dates.filter((ed) => ed.Id === matchingEventDateId)[0];
      setEvent(eventData);
      setOrders([getSampleOrder(eventData, eventDateData)]);
      setBusyMessage(null);
    } else {
      setBusyMessage('Cannot find event date...');
    }
  };

  const getSection = (seatCategoryId) => {
    return linq
      .from(event.SeatingPlans)
      .firstOrDefault((sp) => linq.from(sp.SeatCategories).any((sc) => sc.Id == seatCategoryId));
  };

  useEffect(() => {
    if (domain === 'SampleTickets') {
      loadSample();
    } else {
      loadTickets();
    }
  }, []);

  if (busyMessage) return <Loader>{busyMessage}</Loader>;

  return (
    <div className="tickets-page">
      <Helmet>
        <title>Tickets</title>
        <meta name="description" content={`Your tickets to this event.`} />
      </Helmet>
      {!isPrintPage && <Header />}

      {authRequired ? (
        <Modal theme={event && event.Theme}>
          <div className="content">
            <div className="ticket-rip" />

            <div className="body">
              {busyMessage ? (
                <Loader inline={true}>{busyMessage}</Loader>
              ) : (
                <>
                  <table className="blocks">
                    <tbody>
                      <BlockInfo type={InfoType.Info}>
                        You must be signed in as a Seaty user in order to view these tickets. Please sign in or create
                        an account that has permission to view tickets associated with this email address.
                      </BlockInfo>
                    </tbody>
                  </table>

                  <div className="spacer-x2" />

                  <SpacerTable>
                    <Button
                      className="large"
                      onExecute={() => {
                        setShowSignIn(true);
                      }}
                      text={`Sign In`}
                    />
                    <Button
                      className="large"
                      onExecute={() => {
                        setShowSignUp(true);
                      }}
                      text={`Create an account`}
                    />
                  </SpacerTable>
                </>
              )}
              <SolidLine />

              <SpacerTable className="small-font">
                All accounts are subject to the Seaty{' '}
                <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a> and{' '}
                <a href="https://seaty.co.uk/Docs/Privacy">Privacy Policy</a>.
              </SpacerTable>
            </div>

            <div className="ticket-rip bottom" />
          </div>
        </Modal>
      ) : (
        <>
          {domain === 'SampleTickets' && (
            <div className="page-title">
              Sample Tickets
              <div className="subtitle">
                Intended only to give event organisers an idea of the layout of their tickets
              </div>
            </div>
          )}
          <div className="toolbar donotprint" style={{ borderBottom: '4px solid #ebebeb' }}>
            <div className="buttons">
              <Link to={`/${event.EventTag}`}>
                <SVGGoToEvent />
                Go to event
              </Link>
              <button
                onClick={() => {
                  // Select all .summary elements
                  document.querySelectorAll('.summary').forEach((element) => {
                    element.classList.remove('donotprint');
                  });
                  (window as any).print();
                }}
              >
                <SVGPrint />
                Print
              </button>
              <button
                onClick={() => {
                  // Select all .summary elements
                  document.querySelectorAll('.summary').forEach((element) => {
                    element.classList.add('donotprint');
                  });
                  (window as any).print();
                }}
              >
                <SVGPrint />
                Print tickets only
              </button>
              {isAdmin && (
                <>
                  {orders.length === 1 && (
                    <button onClick={() => setOrder({ orderId: orders[0].Id, event: event })}>
                      <SVGTicket />
                      View order
                    </button>
                  )}
                  <button onClick={() => setEventMenu({ eventTag: event.EventTag })}>
                    <SVGOptions />
                    Options
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="container-outer" style={{ margin: '0' }}>
            <div className="container-inner">
              <div className="section-to-print">
                {orders.map((order) => {
                  return (
                    <React.Fragment key={order.Id}>
                      <div className="summary">
                        <div style={{ float: 'right', textAlign: 'right' }}>
                          {event.Organisation.LogoUrl ? (
                            <img
                              alt="Organisation"
                              style={{ marginBottom: '6px', maxWidth: '200px', height: '50px' }}
                              src={event.Organisation.LogoUrl}
                            />
                          ) : (
                            <h2>{event.Organisation.Name} presents</h2>
                          )}

                          <img alt="Powered by Seaty" className="powered-by" src={SVGHelper.get('PoweredBy')} />
                          <br />
                          {order.UserNotes && (
                            <p style={{ fontSize: '14px', paddingTop: '0', fontStyle: 'italic' }}>{order.UserNotes}*</p>
                          )}
                        </div>

                        <p style={{ maxWidth: '65%' }}>
                          <h1>{event.Name}</h1>
                          <h2>{DateHelper.asDateAtTimeAmPm((order.EventDate as any).DateAsString)}</h2>
                          <h2>{event.Venue.Name}</h2>
                          {event.Venue && event.Venue.StreetAddress && event.Venue.StreetAddress.length > 0 && (
                            <h2>
                              {event.Venue.StreetAddress}
                              {event.Venue.City && event.Venue.City.length > 0 && ', ' + event.Venue.City}
                              {event.Venue.Postcode && event.Venue.Postcode.length > 0 && ', ' + event.Venue.Postcode}
                            </h2>
                          )}
                          <br />

                          <div>
                            Order <strong>#{order.Id}</strong>
                            {order.Purchase && order.Purchase.Type && order.Purchase.Type.toLowerCase() == 'stripe' && (
                              <div>Paid online by card</div>
                            )}
                          </div>
                        </p>
                        <br />
                        <p style={{ fontStyle: 'italic', fontSize: '14px' }}>
                          This page is an order summary and not a ticket.
                        </p>
                        {order.CollectAtBoxOffice && (
                          <p style={{ fontStyle: 'italic', fontSize: '14px' }}>Requested box office collection.</p>
                        )}
                        <br />

                        {order.QuestionAnswers && (
                          <>
                            {order.QuestionAnswers.map((qa) => (
                              <p className="question-font" key={qa.QuestionName}>
                                <strong>{qa.QuestionName}</strong>: {qa.Text}
                              </p>
                            ))}
                            <br />
                          </>
                        )}

                        <table style={{ width: '100%' }}>
                          <tbody>
                            <tr style={{ fontWeight: 900, borderBottom: '1px solid lightgray' }}>
                              <th style={{ width: '40%' }}>Description</th>
                              <th style={{ width: '50%' }}></th>
                              <th style={{ width: '10%', textAlign: 'right' }}>Price</th>
                            </tr>

                            {linq
                              .from(order.Seats)
                              .orderBy((s) => s.Merchandise)
                              .thenBy((s) => s.Group)
                              .thenBy((s) => {
                                if (NumberHelper.isNumeric(s.Name)) {
                                  return parseInt(s.Name);
                                } else {
                                  return s.Name;
                                }
                              })
                              .where((s) => s.Cancelled != true)
                              .toArray()
                              .map((ticket: ITicket) => {
                                const seatingPlan =
                                  ticket.SeatCategoryId &&
                                  event.SeatingPlans.length > 1 &&
                                  getSection(ticket.SeatCategoryId);

                                const isSeated =
                                  ticket.SeatCategoryId != null &&
                                  ticket.SeatCategoryId != undefined &&
                                  ticket.SeatCategoryId > 0;

                                return (
                                  <tr key={`${order.Id}_SUMMARY_${ticket.Group}_${ticket.Name}`}>
                                    {ticket.Merchandise ? (
                                      <td
                                        colSpan={isSeated ? 1 : 2}
                                        style={{ fontWeight: 400 }}
                                        className="ticket-font"
                                      >
                                        {ticket.Group}
                                        {ticket.Name}
                                        {'  '} {ticket.CategoryName}
                                      </td>
                                    ) : (
                                      <td
                                        colSpan={isSeated ? 1 : 2}
                                        style={{ fontWeight: 400 }}
                                        className="ticket-font"
                                      >
                                        {ticket.Group}
                                        {ticket.Name}
                                        {'  '}
                                        {!isSeated && (
                                          <>
                                            {ticket.CategoryName +
                                              (!ticket.SeatCategoryName ||
                                              ticket.SeatCategoryName == ticket.CategoryName
                                                ? ''
                                                : ' ' + ticket.SeatCategoryName)}
                                          </>
                                        )}
                                      </td>
                                    )}
                                    {isSeated && (
                                      <td style={{}}>
                                        {`${seatingPlan ? seatingPlan.Name + ' - ' : ''}` +
                                          ticket.CategoryName +
                                          (!ticket.SeatCategoryName || ticket.SeatCategoryName == ticket.CategoryName
                                            ? ''
                                            : ' ' + ticket.SeatCategoryName)}
                                      </td>
                                    )}
                                    <td style={{ textAlign: 'right' }}>
                                      {CurrencyHelper.formatCurrency(event.CurrencySymbol, ticket.PriceAsInt)}
                                    </td>
                                  </tr>
                                );
                              })}

                            {order.Discounts.map((discount) => (
                              <tr key={`${order.Id}_DISCOUNT_${discount.Id}`}>
                                <td style={{}}>
                                  {!discount.Name
                                    ? 'Discount'
                                    : discount.Name.toLocaleLowerCase().indexOf('discount') > -1
                                      ? discount.Name
                                      : discount.Name + ' discount'}
                                </td>
                                <td style={{ fontStyle: 'italic' }}></td>
                                <td style={{ textAlign: 'right' }}>
                                  -{CurrencyHelper.formatCurrency(event.CurrencySymbol, discount.Amount)}
                                </td>
                              </tr>
                            ))}

                            {order.Purchase &&
                              (!order.Purchase.AbsorbFees ||
                                (order.Purchase.PurchaseCosts.HandlingFee != null &&
                                  order.Purchase.PurchaseCosts.HandlingFee > 0)) && (
                                <>
                                  <tr style={{ fontWeight: 700, borderBottom: '1px solid lightgray' }}>
                                    <td></td>
                                    <td style={{ textAlign: 'right' }}>Sub total</td>
                                    <td style={{ textAlign: 'right' }}>
                                      {order.Purchase.PurchaseCosts.TotalCostWithoutFeesFormatted}
                                    </td>
                                  </tr>

                                  {order.Purchase.PurchaseCosts.HandlingFee != null &&
                                    order.Purchase.PurchaseCosts.HandlingFee > 0 && (
                                      <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}>Handling fee</td>
                                        <td style={{ textAlign: 'right' }}>
                                          {CurrencyHelper.formatCurrency(
                                            event.CurrencySymbol,
                                            order.Purchase.PurchaseCosts.HandlingFee,
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                  {!order.Purchase.AbsorbFees &&
                                    order.Purchase.PurchaseCosts.OrganisationServiceFee != null &&
                                    order.Purchase.PurchaseCosts.OrganisationServiceFee > 0 && (
                                      <tr>
                                        <td colSpan={2} style={{ textAlign: 'right' }}>
                                          {event.Organisation.Name} Service Fee
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                          {CurrencyHelper.formatCurrency(
                                            event.CurrencySymbol,
                                            order.Purchase.PurchaseCosts.OrganisationServiceFee,
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                  {order.Purchase?.PurchaseCosts?.EnhancedRefundServiceFee > 0 && (
                                    <tr>
                                      <td></td>
                                      <td style={{ textAlign: 'right' }}>Enhanced Refund Service</td>
                                      <td style={{ textAlign: 'right' }}>
                                        {order.Purchase.PurchaseCosts.EnhancedRefundServiceFeeFormatted}
                                      </td>
                                    </tr>
                                  )}
                                  {!order.Purchase.AbsorbFees && (
                                    <tr>
                                      <td></td>
                                      <td style={{ textAlign: 'right' }}>Seaty Service Fee</td>
                                      <td style={{ textAlign: 'right' }}>
                                        {order.Purchase.PurchaseCosts.TotalFeesFormatted}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}

                            <tr style={order.Purchase != null ? null : { borderTop: '1px solid lightgrey' }}>
                              <td></td>
                              <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                              <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                {order.Purchase != null && order.Purchase.PurchaseCosts
                                  ? order.Purchase.PurchaseCosts.TotalCostWithFeesFormatted
                                  : order.Price}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <p className="plain-font" style={{ fontSize: '10px' }}>
                          This order was placed on {order.Date} by {order.PlacedBy} for {order.PlacedFor} in agreement
                          with terms of service found at http://Seaty.co.uk/Docs/TermsOfService.
                        </p>
                        {event.TermsAndConditions && (
                          <p className="plain-font" style={{ fontSize: '10px' }}>
                            Terms and conditions outlined by event organiser: {event.TermsAndConditions}
                          </p>
                        )}
                        {order.UserNotes && (
                          <p className="plain-font" style={{ fontSize: '10px' }}>
                            * Any text marked with an asterisk was entered by the user as a note for reference purposes
                            only.
                          </p>
                        )}
                      </div>

                      <div className="tickets">
                        {linq
                          .from(order.Seats)
                          .where((s) => !s.Merchandise)
                          .orderBy((s) => s.Group)
                          .thenBy((s) => {
                            if (NumberHelper.isNumeric(s.Name)) {
                              return parseInt(s.Name);
                            } else {
                              return s.Name;
                            }
                          })
                          .where((s) => s.Cancelled != true)
                          .toArray()
                          .map((ticket, index) => {
                            const seatingPlan =
                              ticket.SeatCategoryId &&
                              event.SeatingPlans.length > 1 &&
                              getSection(ticket.SeatCategoryId);

                            return (
                              <div
                                className="ticket"
                                style={{ position: 'relative' }}
                                key={`${order.Id}_${ticket.SeatCategoryId}_${ticket.Group}_${ticket.Name}`}
                              >
                                <table style={{ width: '100%' }}>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          position: 'relative',
                                          verticalAlign: 'top',
                                          textAlign: 'left',
                                          width: '50%',
                                        }}
                                      >
                                        <table style={{ display: 'inline-block' }}>
                                          <tbody>
                                            <tr style={{ verticalAlign: 'top' }}>
                                              {event.ShowBarcodes && (
                                                <td style={{ verticalAlign: 'top', paddingRight: '25px' }}>
                                                  <QRCode value={ticket.Guid} width={100} height={100} />
                                                </td>
                                              )}

                                              <td style={{ verticalAlign: 'top' }}>
                                                <div
                                                  className="text-left plain-font ticket-number ticket-font"
                                                  style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '46px',
                                                    padding: '0 !important',
                                                    marginTop: '-5px',
                                                    lineHeight: '1em',
                                                    marginBottom: '0',
                                                    color: ticket.GeneralAdmission ? ticket.CategoryColour : 'black',
                                                  }}
                                                >
                                                  {ticket.Group + ticket.Name}
                                                </div>

                                                {!ticket.GeneralAdmission ? (
                                                  <>
                                                    {seatingPlan && <h1>{seatingPlan.Name}</h1>}
                                                    <h1 style={{ color: ticket.CategoryColour }}>
                                                      {ticket.CategoryName}
                                                    </h1>
                                                    {ticket.SeatCategoryName &&
                                                      ticket.SeatCategoryName != ticket.CategoryName && (
                                                        <h1 style={{ color: ticket.SeatCategoryColour }}>
                                                          {ticket.SeatCategoryName}
                                                        </h1>
                                                      )}
                                                  </>
                                                ) : (
                                                  <div
                                                    className="title text-left plain-font"
                                                    style={{
                                                      marginBottom: '0',
                                                      fontSize: '14px',
                                                      textTransform: 'none',
                                                      color: ticket.CategoryColour,
                                                    }}
                                                  >
                                                    {ticket.CategoryName}
                                                  </div>
                                                )}
                                                <h1>{ticket.PriceAsString}</h1>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        {!ticket.GeneralAdmission && ticket.SeatCategoryDescription && (
                                          <div className="ticket-description">{ticket.SeatCategoryDescription}</div>
                                        )}
                                        {ticket.CategoryDescription && (
                                          <div className="ticket-description">{ticket.CategoryDescription}</div>
                                        )}
                                      </td>
                                      <td style={{ verticalAlign: 'top', width: '50%', textAlign: 'right' }}>
                                        {event.Organisation.LogoUrl ? (
                                          <img
                                            alt="Organisation logo"
                                            style={{
                                              marginBottom: '8px',
                                              width: 'auto',
                                              maxWidth: '300px',
                                              height: '50px',
                                            }}
                                            src={event.Organisation.LogoUrl}
                                          />
                                        ) : (
                                          <h2>{event.Organisation.Name} presents</h2>
                                        )}

                                        <h1>{event.Name}</h1>

                                        <h2>{DateHelper.asDateAtTimeAmPm((order.EventDate as any).DateAsString)}</h2>

                                        {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
                                          <h2>{event.Venue.Name}</h2>
                                        )}
                                        {event.Venue &&
                                          event.Venue.StreetAddress &&
                                          event.Venue.StreetAddress.length > 0 && (
                                            <h3>
                                              {event.Venue.StreetAddress}
                                              {event.Venue.City &&
                                                event.Venue.City.length > 0 &&
                                                ', ' + event.Venue.City}
                                              {event.Venue.Postcode &&
                                                event.Venue.Postcode.length > 0 &&
                                                ', ' + event.Venue.Postcode}
                                            </h3>
                                          )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <table style={{ width: '100%' }}>
                                  <tbody>
                                    <tr>
                                      <td style={{ paddingRight: '15px' }}>
                                        <h3 style={{ marginBottom: '12px' }}>
                                          {event.AdmissionType === AdmissionTypes.PrintedAndMobile && (
                                            <span>Tickets accepted on a phone or printed off</span>
                                          )}
                                          {event.AdmissionType === AdmissionTypes.PrintedOnly && (
                                            <span>Please print and bring this ticket with you</span>
                                          )}
                                          {event.AdmissionType === AdmissionTypes.Exchange && (
                                            <span>
                                              Ticket must be taken to venue box office and exchanged before event starts
                                            </span>
                                          )}
                                          {event.AdmissionType === AdmissionTypes.Custom && (
                                            <span>{event.CustomAdmissionType}</span>
                                          )}

                                          {ticket.UserNotes && (
                                            <div style={{ paddingTop: '3px' }}>
                                              <span
                                                className="text-right"
                                                style={{
                                                  fontStyle: 'italic',
                                                  marginBottom: '0',
                                                  fontSize: '12px',
                                                  textTransform: 'none',
                                                  fontWeight: 100,
                                                }}
                                              >
                                                {ticket.UserNotes}*
                                              </span>
                                            </div>
                                          )}
                                        </h3>
                                      </td>
                                      <td>
                                        <img
                                          alt="Powered by Seaty"
                                          className="powered-by"
                                          src={SVGHelper.get('PoweredBy')}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                {ticket.QuestionAnswers && (
                                  <>
                                    {ticket.QuestionAnswers.map((qa) => (
                                      <p className="question-font" key={qa.QuestionName}>
                                        <strong>{qa.QuestionName}</strong>: {qa.Text}
                                      </p>
                                    ))}
                                  </>
                                )}

                                <div className="plain-font" style={{ fontSize: '10px' }}>
                                  {order.Discounts != null && order.Discounts.length > 0 && (
                                    <span>
                                      Please note that this ticket was part of a discounted order and it's price
                                      represents the original face value of the ticket before any discount was applied.
                                    </span>
                                  )}
                                  Ticket {index + 1} of{' '}
                                  {linq
                                    .from(order.Seats)
                                    .where((s) => !s.Merchandise)
                                    .count()}{' '}
                                  in order <strong>#{order.Id}</strong> issued to <strong>{order.AttendeeName}</strong>{' '}
                                  ({order.Email}) in agreement with the Seaty terms of service, which can be found at
                                  http://Seaty.co.uk/Docs/TermsOfService.
                                  {event.TermsAndConditions &&
                                    ` ${event.Organisation.Name} Terms & Conditions of sale: ${event.TermsAndConditions}`}
                                  {ticket.UserNotes && (
                                    <div>
                                      * Any text marked with an asterisk was entered by the user as a note for reference
                                      purposes only.
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}

                        {linq
                          .from(order.Seats)
                          .where((s) => s.Merchandise)
                          .orderBy((s) => s.Group)
                          .thenBy((s) => {
                            if (NumberHelper.isNumeric(s.Name)) {
                              return parseInt(s.Name);
                            } else {
                              return s.Name;
                            }
                          })
                          .where((s) => s.Cancelled != true)
                          .toArray()
                          .map((merchandise, index) => {
                            return (
                              <div
                                className="ticket"
                                style={{ position: 'relative' }}
                                key={`${order.Id}_${merchandise.SeatCategoryId}_${merchandise.Group}_${merchandise.Name}`}
                              >
                                <table style={{ width: '100%' }}>
                                  <tbody>
                                    <tr>
                                      <td style={{ verticalAlign: 'top', width: '50%', textAlign: 'left' }}>
                                        {event.Organisation.LogoUrl ? (
                                          <img
                                            alt="Organisation logo"
                                            style={{
                                              marginBottom: '8px',
                                              width: 'auto',
                                              maxWidth: '300px',
                                              height: '50px',
                                            }}
                                            src={event.Organisation.LogoUrl}
                                          />
                                        ) : (
                                          <h2>{event.Organisation.Name} presents</h2>
                                        )}

                                        <h1>{event.Name}</h1>

                                        <h2>{DateHelper.asDateAtTimeAmPm((order.EventDate as any).DateAsString)}</h2>

                                        {event.Venue && event.Venue.Name && event.Venue.Name.length > 0 && (
                                          <h2>{event.Venue.Name}</h2>
                                        )}
                                        {event.Venue &&
                                          event.Venue.StreetAddress &&
                                          event.Venue.StreetAddress.length > 0 && (
                                            <h3>
                                              {event.Venue.StreetAddress}
                                              {event.Venue.City &&
                                                event.Venue.City.length > 0 &&
                                                ', ' + event.Venue.City}
                                              {event.Venue.Postcode &&
                                                event.Venue.Postcode.length > 0 &&
                                                ', ' + event.Venue.Postcode}
                                            </h3>
                                          )}

                                        <div
                                          className="title text-left plain-font"
                                          style={{
                                            marginTop: '15px',
                                            marginBottom: '0',
                                            fontSize: '16px',
                                            lineHeight: '18px',
                                            textTransform: 'none',
                                            color: merchandise.CategoryColour,
                                          }}
                                        >
                                          {merchandise.CategoryName}
                                        </div>
                                        <h1>{merchandise.PriceAsString}</h1>
                                        {merchandise.CategoryDescription && (
                                          <div className="ticket-description">{merchandise.CategoryDescription}</div>
                                        )}

                                        <h3 style={{ marginTop: '15px', marginBottom: '18px' }}>
                                          {event.AdmissionType === AdmissionTypes.PrintedAndMobile && (
                                            <span>Receipt accepted on a phone or printed off</span>
                                          )}
                                          {event.AdmissionType === AdmissionTypes.PrintedOnly && (
                                            <span>Please print and bring this receipt with you</span>
                                          )}
                                          {/* {event.AdmissionType === AdmissionTypes.Exchange && <span>Receipt must be taken to venue box office and exchanged before event starts</span>} */}
                                          {event.AdmissionType === AdmissionTypes.Custom && (
                                            <span>{event.CustomAdmissionType}</span>
                                          )}

                                          {merchandise.UserNotes && (
                                            <div style={{ paddingTop: '3px' }}>
                                              <span
                                                className="text-right"
                                                style={{
                                                  fontStyle: 'italic',
                                                  marginBottom: '0',
                                                  fontSize: '12px',
                                                  textTransform: 'none',
                                                  fontWeight: 100,
                                                }}
                                              >
                                                {merchandise.UserNotes}*
                                              </span>
                                            </div>
                                          )}
                                        </h3>
                                      </td>
                                      <td
                                        style={{
                                          position: 'relative',
                                          verticalAlign: 'top',
                                          textAlign: 'right',
                                          width: '50%',
                                        }}
                                      >
                                        <QRCode value={merchandise.Guid} width={100} height={100} />{' '}
                                        <div
                                          className="text-right plain-font ticket-number ticket-font"
                                          style={{
                                            fontWeight: 'bold',
                                            fontSize: '24px',
                                            padding: '0 !important',
                                            marginTop: '10px',
                                            lineHeight: '1em',
                                            marginBottom: '0',
                                            color: merchandise.GeneralAdmission ? merchandise.CategoryColour : 'black',
                                          }}
                                        >
                                          {merchandise.Group + merchandise.Name}
                                        </div>
                                        <img
                                          alt="Powered by Seaty"
                                          className="powered-by"
                                          src={SVGHelper.get('PoweredBy')}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                {merchandise.QuestionAnswers && (
                                  <>
                                    {merchandise.QuestionAnswers.map((qa) => (
                                      <p className="question-font" key={qa.QuestionName}>
                                        <strong>{qa.QuestionName}</strong>: {qa.Text}
                                      </p>
                                    ))}
                                  </>
                                )}

                                <div className="plain-font" style={{ fontSize: '10px' }}>
                                  {order.Discounts != null && order.Discounts.length > 0 && (
                                    <span>
                                      Please note that this item was part of a discounted order and it's price
                                      represents the original face value of the item before any discount was applied.
                                    </span>
                                  )}
                                  This is not a ticket and is not valid for entry to an event. Product {index + 1} of{' '}
                                  {linq
                                    .from(order.Seats)
                                    .where((s) => s.Merchandise)
                                    .count()}{' '}
                                  in order <strong>#{order.Id}</strong> issued to <strong>{order.AttendeeName}</strong>{' '}
                                  ({order.Email}) in agreement with the Seaty terms of service, which can be found at
                                  http://Seaty.co.uk/Docs/TermsOfService.
                                  {event.TermsAndConditions &&
                                    ` ${event.Organisation.Name} Terms & Conditions of sale: ${event.TermsAndConditions}`}
                                  {merchandise.UserNotes && (
                                    <div>
                                      * Any text marked with an asterisk was entered by the user as a note for reference
                                      purposes only.
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TicketsPage;
