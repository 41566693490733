import { FunctionComponent, useEffect, useState } from 'react';
import AdminApi from '../../../../api/AdminApi';
import Block from '../../../../components/Block';
import BlockHeader from '../../../../components/BlockHeader';
import BlockInfo, { InfoType } from '../../../../components/BlockInfo';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import SpacerTable from '../../../../components/SpacerTable';
import StringHelper from '../../../../helpers/StringHelper';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import OrderDashboard from '../../../../components/order_dashboard/OrderDashboard';
import MembershipButton from '../../../../modals/AttendeeOverview/MembershipButton';
import MembershipSection from '../../../../modals/AttendeeOverview/MembershipSection';
import { IOrganisationMember } from '../../../../services/MemberService';

export interface IProps {
  organisation: IOrganisation;
  email: string;
  overviewRefreshedCallback: (overview: any) => void;
}

enum Section {
  Dashboard = 'Dashboard',
  Membership = 'Membership',
  Orders = 'Orders',
  Order = 'Order',
}

interface IOverview {
  Email: string;
  Orders: any;
  User: any;
  Member: IOrganisationMember;
  IsMember: boolean;
  UserId: number;
  MembershipsEnabled: any;
  MemberType: any;
  MemberTypes: any;
  MarketingOrders?: number[];
}

const AdminAttendeeOverview: FunctionComponent<IProps> = (props) => {
  const { organisation, email, overviewRefreshedCallback } = props;

  const [section, setSection] = useState<Section>(Section.Dashboard);

  const [busyMessage, setBusyMessage] = useState('Refreshing overview...');
  const [overview, setOverview] = useState<IOverview>(null);
  const [error, setError] = useState(null);
  const [reloadMessage, setReloadMessage] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  useEffect(() => {
    if (props.email != null) {
      refresh(Section.Dashboard);
    }
  }, [email]);

  const clearMarketingPermission = () => {
    setBusyMessage('Removing marketing permission...');
    setReloadMessage(null);

    AdminApi.request('DELETE', `/api/Marketing`, { organisationId: organisation.Id, email: email })
      .then((response) => {
        setSection(section == null ? Section.Dashboard : section);
        setBusyMessage(null);

        return refresh();
      })
      .catch(() => {
        setSection(Section.Dashboard);
        setBusyMessage(null);
        setError('Error when finding orders');
      });
  };

  const refresh = (section?: Section) => {
    setBusyMessage('Refreshing overview...');
    setReloadMessage(null);

    AdminApi.request('GET', `/api/AttendeeOverviewOrganisation?organisationId=${organisation.Id}&email=${email}`)
      .then((response) => {
        setOverview(response);
        setSection(section == null ? Section.Dashboard : section);
        setBusyMessage(null);

        if (overviewRefreshedCallback) {
          overviewRefreshedCallback(response);
        }
      })
      .catch((err) => {
        setSection(Section.Dashboard);
        setBusyMessage(null);
        setError(err);
      });
  };

  const hasOneOrder = () => {
    var orders = overview.Orders;
    return orders.length == 1;
  };

  const getUserField = () => {
    return (
      <Block>
        <BlockHeader>Attendee</BlockHeader>
        {overview.UserId == 0 ? null : <div>{overview.User.Name}</div>}
        <a href={`mailto:${overview.Email}`}>{overview.Email}</a>
        {overview.UserId != 0 ? null : (
          <div>
            {overview.Email == null || overview.Email.length == 0
              ? 'There is no email address specified for this member.'
              : 'This email address has no account on Seaty.'}
          </div>
        )}
      </Block>
    );
  };

  const renderDashboardSection = () => {
    var orders = overview.Orders;

    return (
      <>
        <table className="blocks">
          <tbody>
            {getUserField()}

            {overview.Email == null || overview.Email.length == 0 ? null : (
              <>
                <Block
                  className="route"
                  onClick={() => {
                    if (orders.length == 1) {
                      setSelectedOrderId(orders[0].Id);
                      setSection(Section.Order);
                    } else {
                      setSection(Section.Orders);
                    }
                  }}
                >
                  <BlockHeader>
                    {StringHelper.AddSWhenMany(orders.length, 'Order')}
                    {orders.length == 1 ? ' #' + orders[0].Id : ''}
                  </BlockHeader>
                </Block>
              </>
            )}

            {overview.MembershipsEnabled && (
              <MembershipButton
                organisationName={organisation.Name}
                isMember={overview.IsMember}
                memberTypeName={overview.MemberType && overview.MemberType.Name}
                onClick={() => setSection(Section.Membership)}
              />
            )}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          {overview.MarketingOrders && overview.MarketingOrders.length > 0 && (
            <Button className="bad" onExecute={() => clearMarketingPermission()} text={'Remove marketing permission'} />
          )}
          <Button onExecute={() => refresh()} text={'Refresh'} />
        </SpacerTable>
      </>
    );
  };

  const renderOrdersSection = () => {
    var orders = overview.Orders;

    return (
      <>
        <table className="blocks">
          <tbody>
            {getUserField()}

            {orders &&
              orders.length > 0 &&
              orders.map((order) => {
                return (
                  <Block
                    key={`Order_${order.Id}`}
                    className="route"
                    onClick={() => {
                      setSelectedOrderId(order.Id);
                      setSection(Section.Order);
                    }}
                  >
                    <BlockHeader>Order {' #' + order.Id}</BlockHeader>
                    <div>
                      {order.Tickets} {StringHelper.AddSWhenMany(order.Tickets, 'ticket')}
                    </div>
                  </Block>
                );
              })}
          </tbody>
        </table>

        <div className="spacer" />
        <SpacerTable>
          <Button onExecute={() => setSection(Section.Dashboard)} text={'Back to overview'} />
        </SpacerTable>
      </>
    );
  };

  const renderMembershipSection = () => {
    return (
      <>
        <MembershipSection organisation={organisation} overview={overview} setError={setError} />
        <div className="spacer" />
        <SpacerTable>
          <Button
            onExecute={() => {
              refresh();
              setSection(Section.Dashboard);
            }}
            text={'Back to overview'}
          />
        </SpacerTable>
      </>
    );
  };

  const renderOrderDashboardSection = () => {
    return (
      <>
        <OrderDashboard orderId={selectedOrderId} />
        <div className="spacer" />
        <SpacerTable>
          {hasOneOrder() ? (
            <Button onExecute={() => refresh(Section.Dashboard)} text={'Back to overview'} />
          ) : (
            <Button onExecute={() => refresh(Section.Orders)} text={'Back to orders'} />
          )}
        </SpacerTable>
      </>
    );
  };

  if (reloadMessage != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Info}>{reloadMessage}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button onExecute={() => refresh()} text={'Back to order'} />
        </SpacerTable>
      </>
    );
  }

  if (busyMessage != null) {
    return <Loader inline={true}>{busyMessage}</Loader>;
  }

  if (error != null) {
    return (
      <>
        <table className="blocks">
          <tbody>
            <BlockInfo type={InfoType.Error}>{error}</BlockInfo>
          </tbody>
        </table>
        <div className="spacer" />
        <SpacerTable>
          <Button
            onExecute={() => {
              setError(null);
              refresh();
            }}
            text={'Dismiss'}
          />
        </SpacerTable>
      </>
    );
  }

  const sectionRenderMap: { [key in Section]?: () => JSX.Element | null } = {
    [Section.Dashboard]: renderDashboardSection,
    [Section.Membership]: renderMembershipSection,
    [Section.Order]: renderOrderDashboardSection,
    [Section.Orders]: renderOrdersSection,
  };

  const renderSection = () => {
    const renderFunction = sectionRenderMap[section];
    return renderFunction ? renderFunction() : null;
  };

  return renderSection();
};

export default AdminAttendeeOverview;
