import linq from 'linq';
import moment from 'moment';
import React from 'react';
import CurrencyHelper from '../../../../helpers/CurrencyHelper';
import { IOrganisation } from '../../../../interfaces/IOrganisation';
import { ILedgerRow } from './ILedgerRow';

interface IProps {
  period: string;
  showVAT: boolean;
  organisation: IOrganisation;
  ledgerPurchases: Array<ILedgerRow>;
  ledgerRefunds: Array<ILedgerRow>;
}

const LedgerDailyTable: React.FC<IProps> = (props) => {
  const { showVAT, organisation } = props;

  const jointLinq = linq.from(props.ledgerRefunds.concat(props.ledgerPurchases));

  const dailyGrouped = jointLinq
    .groupBy((j) => moment(j.RefundAmount && j.RefundAmount > 0 ? j.RefundDate : j.DateCompleted).format('DD MMM YY'))
    .orderByDescending((j) =>
      moment(
        j.first().RefundAmount && j.first().RefundAmount > 0 ? j.first().RefundDate : j.first().DateCompleted,
      ).format('DD MMM YY'),
    );

  return (
    <>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr style={{ fontWeight: 600 }}>
            <td style={{ width: '20%' }} colSpan={2}>
              {moment(props.period).format('MMMM YYYY')} daily average
            </td>
            <td style={{ width: '10%', textAlign: 'right' }}>
              {CurrencyHelper.formatCurrency(
                jointLinq.first().CurrencySymbol,
                jointLinq.sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.RefundAmount : o.TotalPrice)) /
                  dailyGrouped.count(),
              )}
            </td>
            {jointLinq.any((p) => p.StripeConnect) && (
              <td style={{ width: '10%', textAlign: 'right' }}>
                {CurrencyHelper.formatCurrency(
                  jointLinq.first().CurrencySymbol,
                  linq
                    .from(jointLinq)
                    .sum((o) =>
                      o.RefundAmount && o.RefundAmount > 0
                        ? o.StripeConnect
                          ? 0 - o.GatewayFeeRefunded
                          : 0
                        : o.GatewayFee,
                    ) / dailyGrouped.count(),
                )}
              </td>
            )}

            <td style={{ width: '10%', textAlign: 'right' }}>
              {CurrencyHelper.formatCurrency(
                '£',
                linq
                  .from(jointLinq)
                  .sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.SeatyFeeGBPRefunded : o.SeatyFeeGBP)) /
                  dailyGrouped.count(),
              )}
            </td>

            {showVAT && (
              <td style={{ width: '10%', textAlign: 'right' }}>
                {CurrencyHelper.formatCurrency(
                  '£',
                  linq.from(jointLinq).sum((o) => o.VATAdjustment) / dailyGrouped.count(),
                )}
              </td>
            )}
          </tr>
          <tr>
            <td style={{ width: '20%' }} colSpan={2}>
              Projections
            </td>
            <td style={{ width: '10%', textAlign: 'right' }}>
              {CurrencyHelper.formatCurrency(
                jointLinq.first().CurrencySymbol,
                (moment(props.period).daysInMonth() *
                  jointLinq.sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.RefundAmount : o.TotalPrice))) /
                  dailyGrouped.count(),
              )}
            </td>
            {jointLinq.any((p) => p.StripeConnect) && (
              <td style={{ width: '10%', textAlign: 'right' }}>
                {CurrencyHelper.formatCurrency(
                  jointLinq.first().CurrencySymbol,
                  (moment(props.period).daysInMonth() *
                    linq
                      .from(jointLinq)
                      .sum((o) =>
                        o.RefundAmount && o.RefundAmount > 0
                          ? o.StripeConnect
                            ? 0 - o.GatewayFeeRefunded
                            : 0
                          : o.GatewayFee,
                      )) /
                    dailyGrouped.count(),
                )}
              </td>
            )}

            <td style={{ width: '10%', textAlign: 'right' }}>
              {CurrencyHelper.formatCurrency(
                '£',
                (moment(props.period).daysInMonth() *
                  linq
                    .from(jointLinq)
                    .sum((o) => (o.RefundAmount && o.RefundAmount > 0 ? 0 - o.SeatyFeeGBPRefunded : o.SeatyFeeGBP))) /
                  dailyGrouped.count(),
              )}
            </td>

            {showVAT && (
              <td style={{ width: '10%', textAlign: 'right' }}>
                {CurrencyHelper.formatCurrency(
                  '£',
                  (moment(props.period).daysInMonth() * linq.from(jointLinq).sum((o) => o.VATAdjustment)) /
                    dailyGrouped.count(),
                )}
              </td>
            )}
          </tr>
        </tbody>
      </table>

      <br />
      <br />

      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <th style={{ width: '10%' }}>Day</th>
            <th style={{ width: '10%' }}>Type</th>
            <th style={{ width: '10%', textAlign: 'right' }}>Amount</th>
            {jointLinq.any((p) => p.StripeConnect) && <th style={{ width: '10%', textAlign: 'right' }}>Stripe fee</th>}
            <th style={{ width: '10%', textAlign: 'right' }}>Service fee</th>
            {organisation.OrganisationTag.toLowerCase() === 'seaty' && (
              <th style={{ width: '10%', textAlign: 'right' }}>ERS Fee</th>
            )}

            {showVAT && <th style={{ width: '10%', textAlign: 'right' }}>VAT</th>}
          </tr>

          {dailyGrouped.select((j) => {
            const ledgerPurchasesLinq = linq
              .from(props.ledgerPurchases)
              .where(
                (p) =>
                  p.CurrencySymbol === j.first().CurrencySymbol &&
                  moment(
                    j.first().RefundAmount && j.first().RefundAmount > 0
                      ? j.first().RefundDate
                      : j.first().DateCompleted,
                  ).format('DD MMM YY') === moment(p.DateCompleted).format('DD MMM YY'),
              );

            const ledgerRefundsLinq = linq
              .from(props.ledgerRefunds)
              .where(
                (p) =>
                  p.CurrencySymbol === j.first().CurrencySymbol &&
                  moment(
                    j.first().RefundAmount && j.first().RefundAmount > 0
                      ? j.first().RefundDate
                      : j.first().DateCompleted,
                  ).format('DD MMM YY') === moment(p.RefundDate).format('DD MMM YY'),
              );

            return (
              <React.Fragment
                key={moment(
                  j.first().RefundAmount && j.first().RefundAmount > 0 ? j.first().RefundDate : j.first().DateCompleted,
                ).format('D MMM')}
              >
                {ledgerPurchasesLinq && ledgerPurchasesLinq.count() > 0 && (
                  <tr>
                    <td>
                      {moment(
                        j.first().RefundAmount && j.first().RefundAmount > 0
                          ? j.first().RefundDate
                          : j.first().DateCompleted,
                      ).format('D MMM')}
                    </td>
                    <td>Purchases</td>
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        ledgerPurchasesLinq.first().CurrencySymbol,
                        ledgerPurchasesLinq.sum((o) => o.TotalPrice),
                      )}
                    </td>

                    {jointLinq.any((p) => p.StripeConnect) && (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          ledgerPurchasesLinq.first().CurrencySymbol,
                          ledgerPurchasesLinq.sum((o) => o.GatewayFee),
                        )}
                      </td>
                    )}

                    {organisation.OrganisationTag.toLowerCase() === 'seaty' ? (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          '£',
                          ledgerPurchasesLinq.sum((o) => o.SeatyFeeGBP),
                        )}
                      </td>
                    ) : (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          ledgerPurchasesLinq.first().CurrencySymbol,
                          ledgerPurchasesLinq.sum((o) => o.SeatyFee),
                        )}
                      </td>
                    )}

                    {organisation.OrganisationTag.toLowerCase() === 'seaty' && (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          '£',
                          ledgerPurchasesLinq.sum((o) => o.EnhancedRefundFee),
                        )}
                      </td>
                    )}

                    {showVAT && (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          '£',
                          ledgerPurchasesLinq.sum((o) => o.VATAdjustment),
                        )}
                      </td>
                    )}
                  </tr>
                )}

                {ledgerRefundsLinq && ledgerRefundsLinq.count() > 0 && (
                  <tr style={{ color: '#b6b6b6' }}>
                    <td>
                      {moment(
                        j.first().RefundAmount && j.first().RefundAmount > 0
                          ? j.first().RefundDate
                          : j.first().DateCompleted,
                      ).format('D MMM')}
                    </td>

                    <td>Refunds</td>
                    <td style={{ textAlign: 'right' }}>
                      {CurrencyHelper.formatCurrency(
                        ledgerRefundsLinq.first().CurrencySymbol,
                        ledgerRefundsLinq.sum((o) => 0 - o.RefundAmount),
                      )}
                    </td>

                    {jointLinq.any((p) => p.StripeConnect) && (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          ledgerRefundsLinq.first().CurrencySymbol,
                          0 - ledgerRefundsLinq.sum((o) => (o.StripeConnect ? o.GatewayFeeRefunded : 0)),
                        )}
                      </td>
                    )}

                    {organisation.OrganisationTag.toLowerCase() === 'seaty' ? (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          '£',
                          ledgerRefundsLinq.sum((o) => 0 - o.SeatyFeeGBPRefunded),
                        )}
                      </td>
                    ) : (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          ledgerRefundsLinq.first().CurrencySymbol,
                          ledgerRefundsLinq.sum((o) => 0 - o.SeatyFeeRefunded),
                        )}
                      </td>
                    )}

                    {showVAT && (
                      <td style={{ textAlign: 'right' }}>
                        {CurrencyHelper.formatCurrency(
                          '£',
                          ledgerRefundsLinq.sum((o) => o.VATAdjustment),
                        )}
                      </td>
                    )}
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default LedgerDailyTable;
